import React from "react"
import { motion } from "framer-motion"
import tw from "twin.macro"
import styled from "styled-components"
import { css } from "styled-components/macro" //eslint-disable-line
import useAnimatedNavToggler from "../../helpers/useAnimatedNavToggler.js"
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer'
import logo from 'assets/images/logoo.png'
import { ReactComponent as MenuIcon } from "feather-icons/dist/icons/menu.svg"
import { ReactComponent as CloseIcon } from "feather-icons/dist/icons/x.svg"


const Header = tw.header`
  flex justify-between items-center
  max-w-screen-xl mx-auto 
`

export const NavLinks = tw.div`inline-block`

/* hocus: stands for "on hover or focus"
 * hocus:bg-blue-700 will apply the bg-blue-700 class on hover or focus
 */
export const NavLink = tw.a`
  text-lg my-2 lg:text-sm lg:mx-6 lg:my-0
  font-semibold tracking-wide transition duration-300
  pb-1 border-b-2 border-transparent hover:border-blue-500 hocus:text-blue-500
`


export const PrimaryLink = tw(NavLink)`
  lg:mx-0
  px-8 py-3 rounded bg-blue-500 text-gray-100
  hocus:bg-blue-700 hocus:text-gray-200 focus:shadow-outline
  border-b-0
`

export const LogoLink = styled(NavLink)`
  ${tw`flex items-center font-black border-b-0 text-2xl! ml-0!`}

  img {
    ${tw`w-40 mr-3`}
  }
`

export const MobileNavLinksContainer = tw.nav`flex flex-1 items-center justify-between`
export const NavToggle = tw.button`
  lg:hidden z-20 focus:outline-none hocus:text-blue-500 transition duration-300
`
export const MobileNavLinks = motion.custom(styled.div`
  ${tw`lg:hidden z-10 fixed top-0 inset-x-0 mx-4 my-6 p-8 border text-center rounded-lg text-gray-900 bg-white`}
  ${NavLinks} {
    ${tw`flex flex-col items-center`}
  }
`)

export const DesktopNavLinks = tw.nav`
  hidden lg:flex flex-1 justify-between items-center
`

export default ({ roundedHeaderButton = false, logoLink, links, className, collapseBreakpointClass = "lg" }) => {
	const [open, setOpen] = React.useState(false)


	const list = () => (
		<MobileNavLinks animate={animation} css={collapseBreakpointCss.mobileNavLinks}>
			{links}
		</MobileNavLinks>
	)

	const defaultLinks = [
		<NavLinks key={1}>
			<NavLink href="/">Inicio</NavLink>
			<NavLink href="/components/innerPages/AboutUsPage">Nosotros</NavLink>
			<NavLink href="/components/landingPages/ServiciosLandingPage">Servicios</NavLink>
			<NavLink href="/components/innerPages/BlogIndexPage">Noticias</NavLink>
			<NavLink href="/components/innerPages/CotizacionPage">Cotización</NavLink>
			<NavLink href="https://lipaduanas.tracking.sumax.pe/#/auth/login" target='_blank'>Tracking</NavLink>
			<NavLink href="/components/innerPages/TarifarioPage">Tarifario</NavLink>
			<NavLink href="/components/innerPages/ReclamosPage">Libro de Reclamaciones</NavLink>
		</NavLinks>
	]

	const { showNavLinks, animation } = useAnimatedNavToggler()
	const collapseBreakpointCss = collapseBreakPointCssMap[collapseBreakpointClass]

	const defaultLogoLink = (
		<LogoLink href="/">
			<img src={logo} alt="logo" />
		</LogoLink>
	)

	logoLink = logoLink || defaultLogoLink
	links = links || defaultLinks

	return (
		<Header className={className || "header-light"}>
			<DesktopNavLinks css={collapseBreakpointCss.desktopNavLinks}>
				{logoLink}
				{links}
			</DesktopNavLinks>

			<div>
				<React.Fragment>
					<SwipeableDrawer
						anchor='bottom'
						open={open}
						onClose={() => setOpen(false)}
						onOpen={() => setOpen(true)}
					>
						{list()}
					</SwipeableDrawer>
				</React.Fragment>
			</div>

			<MobileNavLinksContainer css={collapseBreakpointCss.mobileNavLinksContainer}>
				{logoLink}
				<NavToggle onClick={() => setOpen(true)} className={showNavLinks ? "open" : "closed"}>
					{showNavLinks ? <CloseIcon tw="w-6 h-6" /> : <MenuIcon tw="w-6 h-6" />}
				</NavToggle>
			</MobileNavLinksContainer>
		</Header>
	)
}


const collapseBreakPointCssMap = {
	sm: {
		mobileNavLinks: tw`sm:hidden`,
		desktopNavLinks: tw`sm:flex`,
		mobileNavLinksContainer: tw`sm:hidden`
	},
	md: {
		mobileNavLinks: tw`md:hidden`,
		desktopNavLinks: tw`md:flex`,
		mobileNavLinksContainer: tw`md:hidden`
	},
	lg: {
		mobileNavLinks: tw`lg:hidden`,
		desktopNavLinks: tw`lg:flex`,
		mobileNavLinksContainer: tw`lg:hidden`
	},
	xl: {
		mobileNavLinks: tw`lg:hidden`,
		desktopNavLinks: tw`lg:flex`,
		mobileNavLinksContainer: tw`lg:hidden`
	}
}
