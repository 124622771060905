import React from "react";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Hero from "components/hero/TwoColumnWithVideo.js";
import MainFeature from "components/features/TwoColWithButton.js";
import Footer from "components/footers/MiniCenteredFooter";
import servicio1 from 'assets/images/servicio1.jpg';
import jurisdicciones from 'assets/images/jurisdicciones.jpg';
import TableTiempo from "components/tableTiempo/TableTiempo";

export default () => {
    const Subheading = tw.span`tracking-wider text-sm font-medium`;
    const HighlightedText = tw.span`bg-primary-500 text-gray-100 px-4 transform -skew-x-12 inline-block`;
    const Description = tw.span`inline-block mt-8`;
    const imageCss = tw`rounded-4xl`;
    const Heading = tw.h2`mt-4 font-black text-left text-3xl sm:text-4xl lg:text-5xl text-center md:text-center leading-tight`;
    const TextSecond = tw.p`mt-4 font-black text-left  text-left md:text-left leading-tight font-light`

    return (

        <AnimationRevealPage disabled>
            <Hero roundedHeaderButton={true} />
            <MainFeature
                subheading={<Subheading></Subheading>}
                heading={
                    <>
                        Servicio de Agenciamiento
                        <br /> <HighlightedText></HighlightedText>
                    </>
                }
                description={
                    <Description>
                        LIP Aduanas S.A. brinda el servicio de Agenciamiento Aduanero para
                        las operaciones de Importación y Exportación dentro de los mejores
                        estándares de seguridad y calidad de servicio, con oficinas en las
                        principales aduanas del país. LIP Aduanas S.A. realiza despachos de
                        aduanas para todos los regímenes, tanto en Exportaciones como
                        Importaciones, para carga en general, restringida y peligrosa.
                        <br />
                        <br />
                        <p style={{ color: '#4441b5', fontWeight: 'bold' }}>
                            En caso de requerimiento envie su solicitud de cotización y gustosamente lo atenderemos.
                        </p>
                    </Description>
                }
                buttonRounded={false}
                textOnLeft={false}
                primaryButtonText="Latest Offers"
                imageSrc={
                    servicio1
                }
                imageCss={imageCss}
                imageDecoratorBlob={true}
                imageDecoratorBlobCss={tw`left-1/2 -translate-x-1/2 md:w-32 md:h-32 opacity-25`}
            />
            <Heading style={{ marginTop: '100px' }}>Jurisdicciones en las que Operamos</Heading>
            <div style={{ width: '100%' }}>
                <div style={{ width: '60%', margin: 'auto' }}>
                    <TextSecond style={{ color: '#1f3864', fontSize: '1.3rem' }}>
                        Operamos en las Aduanas Marítima, Aérea y Postal del CALLAO, CHANCHAY, CHICLAYO, CHIMBOTE, PAITA,
                        PISCO, SALAVERRY.
                        <br />
                        <br />
                        Contando para ello, con tecnología de avanzada interconexión y con atención personalizada durante los 365
                        días y las 24 horas.
                    </TextSecond>
                </div>
                <figure>
                    <img
                        src={jurisdicciones}
                        alt="jurisdicciones"
                        style={{ width: '80%', margin: 'auto', marginTop: '50px' }}
                    />
                </figure>
                
            </div>
            <Heading style={{ marginTop: '100px' }}>Tiempos de Atención</Heading>
            <div style={{ width: '80%', margin: 'auto' }}>
                <TableTiempo />
            </div>
            <div style={{ width: '80%', margin: 'auto', marginBottom: '50px' }}>
                <TextSecond style={{ color: '#1f3864', fontSize: '1rem', fontWeight: 'bold' }}>
                    (*) Aplicable desde el inicio de operaciones en las jurisdicciones de Mollendo y Tacna.
                    <br />
                    (**) En caso de Canal Naranja o Rojo, el tiempo puede variar de acuerdo a los requerimientos de SUNAT o sectoriales.
                    <br />
                    (***) El tiempo puede variar de acuerdo a la rectificaciones que se presenten en la DAM provisional.
                </TextSecond>
            </div>
            <Footer />
        </AnimationRevealPage>
    );
}
