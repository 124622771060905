import React from 'react'
import './TableTiempo.css'
const TableTiempo = () => {
    return (
        <section style={{ width: '100%', marginTop: '40px', marginBottom: '40px' }}>
            <table>
                <thead>
                    <tr>
                        <th>JURISDICCIÓN</th>
                        <th colspan="3">DESCRIPCIÓN</th>
                        <th>TIEMPO PROMEDIO DE ATENCIÓN</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td data-label="JURISDICCIÓN" className="jurisdiccion" rowspan="11">
                            CALLAO,<br />
                            CHANCHAY,<br />
                            CHICLAYO,<br />
                            CHIMBOTE,<br />
                            PAITA,<br />
                            PISCO,<br />
                            SALAVERRY,<br />
                            MOLLENDO(*) Y TACNA(*)
                        </td>
                        <td data-label="DESCRIPCIÓN" className="descripcion-principal" rowspan="9">
                            DESPACHO DE IMPORTACIÓN
                        </td>
                        <td class="descripcion-detalle" rowspan="3">
                            <div class="titulo-importacion">IMPORTACIÓN URGENTE</div>
                            Se considera el tiempo del proceso desde la llegada de la mercancía
                            (aéreo), descarga de la nave (marítimo) o llegada del medio de
                            transporte a frontera (terrestre) hasta la obtención del levante.
                        </td>
                        <td class="canales">Canal Verde</td>
                        <td class="tiempo">2 días hábiles</td>
                    </tr>
                    <tr>
                        <td class="canales">Canal Naranja (**)</td>
                        <td class="tiempo">3 días hábiles</td>
                    </tr>
                    <tr>
                        <td class="canales">Canal Rojo (**)</td>
                        <td class="tiempo">4 días hábiles</td>
                    </tr>
                    <tr>
                        <td class="descripcion-detalle" rowspan="3">
                            <div class="titulo-importacion">IMPORTACIÓN ANTICIPADA</div>
                            Se considera el tiempo del proceso desde la llegada de la mercancía
                            (aéreo), descarga de la nave (marítimo) o llegada del medio de
                            transporte a frontera (terrestre) hasta la obtención del levante.
                        </td>
                        <td class="canales">Canal Verde</td>
                        <td class="tiempo">2 días hábiles</td>
                    </tr>
                    <tr>
                        <td class="canales">Canal Naranja (**)</td>
                        <td class="tiempo">3 días hábiles</td>
                    </tr>
                    <tr>
                        <td class="canales">Canal Rojo (**)</td>
                        <td class="tiempo">4 días hábiles</td>
                    </tr>
                    <tr>
                        <td class="descripcion-detalle" rowspan="3">
                            <div class="titulo-importacion">IMPORTACIÓN DIFERIDA</div>
                            Se considera el tiempo del proceso desde la obtención del volante
                            y/o documentos completos por parte del cliente hasta la obtención
                            del levante.
                        </td>
                        <td class="canales">Canal Verde</td>
                        <td class="tiempo">3 días hábiles</td>
                    </tr>
                    <tr>
                        <td class="canales">Canal Naranja (**)</td>
                        <td class="tiempo">4 días hábiles</td>
                    </tr>
                    <tr>
                        <td class="canales">Canal Rojo (**)</td>
                        <td class="tiempo">5 días hábiles</td>
                    </tr>
                    <tr>
                        <td class="descripcion-principal" rowspan="2">
                            DESPACHO DE EXPORTACIÓN
                        </td>
                        <td colSpan="2" class="descripcion-detalle">
                            Se considera el tiempo del proceso desde la recepción de la
                            instrucción de embarque y documentos sustentatorios hasta la
                            numeración de la DAM.
                        </td>
                        <td class="tiempo">3 días hábiles</td>
                    </tr>
                    <tr>
                        <td colSpan="2" class="descripcion-detalle">
                            Se considera el tiempo del proceso desde la obtención de los
                            documentos completos o fecha de embarque hasta la fecha de
                            regularización de la DAM (***)
                        </td>
                        <td class="tiempo">8 días hábiles</td>
                    </tr>
                </tbody>
            </table>
        </section>
    )
}

export default TableTiempo
